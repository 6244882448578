import React from 'react'

import { Helmet } from 'react-helmet'

import Faq from 'react-faq-component';
import Footer from '../components/footer'
import './faqs.css'

const data = {
  title: "FAQ (How it works)",
  rows: [
    {
      title: "Lorem ipsum dolor sit amet,",
      content: "Lorem ipsum dolor sit amet, consectetur "
    },
    {
      title: "Nunc maximus, magna at ultricies elementum",
      content: "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam."
    },
    {
      title: "Curabitur laoreet, mauris vel blandit fringilla",
      content: "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc"
    },
    {
      title: "What is the package version",
      content: "v1.0.5"
    }]
}

const FAQs = (props) => {
  return (
    <div className="blog-post-container">
      <Helmet>
        <title>BlogPost - Notus Pro</title>
        <meta property="og:title" content="BlogPost - Notus Pro" />
      </Helmet>
      <div className="blog-post-hero">
        <div className="blog-post-bg"></div>
      </div>
      <div className="faqs">
      <Faq 
    data={data} 
    styles={{
        bgColor: "white",
        titleTextColor: "#48482a",
        rowTitleColor: "#78789a",
        rowTitleTextSize: 'large',
        rowContentColor: "#48484a",
        rowContentTextSize: '16px',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '50px',
        rowContentPaddingRight: '150px',
        arrowColor: "black",
        }} 
      />
      </div>
      <Footer></Footer>
    </div>
  )
}

export default FAQs
