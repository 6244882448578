import { useState, useEffect, useRef } from "react";
import Flicking, { includes, ViewportSlot } from "@egjs/react-flicking";
import { AutoPlay, Fade, Arrow, Pagination } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import './reviews.css'

var data = require('./reviews.json')

const reviews = () => {
let _plugins = [new AutoPlay({duration:10000}), new Fade("", 0.9), new Arrow(), new Pagination()];
let reviewEntries = data.reviews.map((entry, key) => {
  let author = _ => {}
  if (entry.hasOwnProperty("author")) {
    author = _ => {
      return (
        <div key={key} className="has-text-white flicking-author">- {entry.author}</div>
      )
    }
  }


  // Temporary measure, because we don't want to show reviews with no authors.
  if (author() !== undefined) {
    return (
      <div key={key} className="flicking-panel has-text-white is-size-5">
        {entry.text}
        {author()}
      </div>
    )
  }
  })

  return (
      <Flicking circular={true} horizontal={true} plugins={_plugins} className="review">
        {reviewEntries}
        <ViewportSlot>
        <span className="flicking-arrow-prev is-circle" style={{color: 'rgba(132, 106, 175, 0.6)', backgroundColor: 'rgba(132, 106, 175, 0.6)'}}></span>
        <span className="flicking-arrow-next is-circle" style={{color: 'rgba(132, 106, 175, 0.6)', backgroundColor: 'rgba(132, 106, 175, 0.6)'}}></span>
      </ViewportSlot>
      <ViewportSlot>
        <div className="flicking-pagination"></div>
      </ViewportSlot>
      </Flicking>
      )
}

export default reviews