import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createRef } from 'react'
// import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
  NavLink,
  useLocation,
  useOutlet,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group'
import Header from './components/header'
// import './components/header.css'

// import './fonts/open-sans.ttf'
// import './fonts/lucida-fax.ttf'
import './style.css'
import './views/gallery.css'
import FAQs from './views/faqs'
import Home from './views/home'
import Gallery from './views/gallery'
import ScrollToTop from './components/plugins/scrollToTop'


const App = () => {
  const location = useLocation()
  const currentOutlet = useOutlet()
  const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {}
  
  return (
    <>
      <Header/>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={1000}
          appear={true}
          classNames="fade"
          unmountOnExit
        >
          {(state) => (
            <div ref={nodeRef} className="page">
              {currentOutlet}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

const routes = [
  { path: '/', name: 'Home', element: <Home />, nodeRef: createRef() },
  { path: '/faqs', name: 'FAQs', element: <FAQs />, nodeRef: createRef() },
  { path: '/gallery', name: 'Gallery', element: <Gallery />, nodeRef: createRef() },
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
])

ReactDOM.render(<RouterProvider router={router} />, document.getElementById('app'))
