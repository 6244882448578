import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className="footer-container">
      <div className="footer-container01">
        <div className="footer-container02">
          <h1 className="footer-text textXXL">
            <span>Narrated Abu Hurayrah:</span>
          </h1>
              <span className='footer-text02'>
              The Prophet (ﷺ) said:
              </span>
            <span className='footer-text022'>
              " إِنْ كَانَ فِي شَىْءٍ مِمَّا تَدَاوَيْتُمْ بِهِ خَيْرٌ فَالْحِجَامَةُ "
              </span>
            <span className='footer-text02'>

            The best medical treatment you
apply is cupping.
</span>
              <span className='footer-text03'>
              Graded ‏صحيح (Al-Albani رحمه الله)
              </span>
              <span className='footer-text03'>
              Sunan Abi Dawud 3857
              </span>
        </div>
      </div>
      <span className="footer-text07">
        <span className="textSM">Copyright © 2021 TRIPLE C Therapy</span>
        <br></br>
      </span>
      <a
        href="https://alihamza.co.uk"
        target="_blank"
        rel="noreferrer noopener"
        className="footer-link"
      >
        {props.text}
      </a>
    </div>
  )
}

Footer.defaultProps = {
  text: 'Designed by Ali',
}

Footer.propTypes = {
  text: PropTypes.string,
}

export default Footer
