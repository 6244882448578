import { React, useEffect } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './header.css'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Header = (props) => {
  useEffect(() => {
    const ifVH100 = () => {
    if (window.scrollY > (getWindowDimensions().height * 0.92)) {
      document.querySelector(".header-header").classList.add('fade-bg');
      document.querySelectorAll(".header-heading").forEach((el) => {
        el.classList.add('fade-colour');
      })
      document.querySelectorAll(".header-supporting-text").forEach((el) => {
        el.classList.add('fade-in');
      })
      document.querySelector(".header-burger-menu").classList.add('fade-fill')
      document.querySelectorAll(".header-icons").forEach((el) => {
        el.classList.add('fade-fill');
      });
    } else {
      document.querySelector(".header-header").classList.remove('fade-bg');
      document.querySelectorAll(".header-heading").forEach((el) => {
        el.classList.remove('fade-colour');
      })
      document.querySelectorAll(".header-supporting-text").forEach((el) => {
        el.classList.remove('fade-in');
      })
      document.querySelector(".header-burger-menu").classList.remove('fade-fill')
      document.querySelectorAll(".header-icons").forEach((el) => {
        el.classList.remove('fade-fill');
      });
    }
  }
  window.addEventListener("scroll", ifVH100);
  ifVH100();
  return () => window.removeEventListener("scroll", ifVH100);
  }, []);

  return (
    <div data-role="Header" className={`header-header ${props.rootClassName} `}>

      <div className="header-container">
        <div className="header-container-links">
          <Link to="/" className="header-navlink">
            <h1 className="header-heading">{props.heading1}</h1>
          </Link>
          <Link to="/faqs" className="header-navlink">
            <h1 className="header-heading">{props.heading2}</h1>
          </Link>
          <Link to="/gallery" className="header-navlink">
            <h1 className="header-heading">{props.heading3}</h1>
          </Link>
        </div>
      </div>

      <div className="header-container header-container-middle">
        <div className="header-container-logo">
          <Link to="/" className="header-navlink">
            <div className="inner-navlink inner-navlink-first"><h1 className="header-text-1 header-supporting-text">TRIPLE C</h1></div>
            <div className="inner-navlink inner-navlink-center"><img className="header-logo" src="../../playground_assets/logo-transparent.png" alt="TRIPLE C"/></div>
            <div className="inner-navlink inner-navlink-last"><h1 className="header-text-2 header-supporting-text">THERAPY</h1></div>
          </Link>
        </div>
      </div>

      <div className="header-container">
        <div className="header-container-socials">
          <a className="header-url" href='https://api.whatsapp.com/send?phone=447306609686'>
            <svg viewBox="0 0 877.7142857142857 1024" className="header-icons">
              <path
                d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"
                className=""
              ></path>
            </svg>
          </a>
          <a className="header-url" href='https://www.instagram.com/triplectherapy/'>
            <svg viewBox="0 0 877.7142857142857 1024" className="header-icons">
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                className=""
              ></path>
            </svg>
          </a>
          <a className="header-url" href='mailto:bookings@triplectherapy.co.uk'>
            <svg viewBox="0 0 1024 1024" className="header-icons">
              <path
                d="M1024 405.714v453.714c0 50.286-41.143 91.429-91.429 91.429h-841.143c-50.286 0-91.429-41.143-91.429-91.429v-453.714c17.143 18.857 36.571 35.429 57.714 49.714 94.857 64.571 190.857 129.143 284 197.143 48 35.429 107.429 78.857 169.714 78.857h1.143c62.286 0 121.714-43.429 169.714-78.857 93.143-67.429 189.143-132.571 284.571-197.143 20.571-14.286 40-30.857 57.143-49.714zM1024 237.714c0 64-47.429 121.714-97.714 156.571-89.143 61.714-178.857 123.429-267.429 185.714-37.143 25.714-100 78.286-146.286 78.286h-1.143c-46.286 0-109.143-52.571-146.286-78.286-88.571-62.286-178.286-124-266.857-185.714-40.571-27.429-98.286-92-98.286-144 0-56 30.286-104 91.429-104h841.143c49.714 0 91.429 41.143 91.429 91.429z"
                className=""
              ></path>
            </svg>
          </a>
        </div>
      </div>

      <div data-type="BurgerMenu" className="header-burger-menu">
        <svg viewBox="0 0 1024 1024" className="header-icon">
          <path
            d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
            className=""
          ></path>
        </svg>
      </div>
      
      <div data-type="MobileMenu" className="header-mobile-menu">
        <div className="header-top">
          <Link to="/" className="header-navlink1">
            <h1 className="header-heading1 textSM">{props.heading11}</h1>
          </Link>
          <div data-type="CloseMobileMenu" className="header-close-menu">
            <svg viewBox="0 0 1024 1024" className="header-icon10">
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
        <div className="header-mid">
          <Link to="/profile" className="header-navlink2">
            <div className="header-container2">
              <svg viewBox="0 0 1024 1024" className="header-icon12">
                <path
                  d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
                  className=""
                ></path>
              </svg>
              <span className="header-text textSM">PROFILE</span>
            </div>
          </Link>
          <Link to="/blog-post" className="header-navlink3">
            <div className="header-container3">
              <svg viewBox="0 0 1024 1024" className="header-icon14">
                <path
                  d="M917.806 229.076c-22.212-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.888 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.984 17.78 50.678 41.878 81.374 72.572zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.326 32 32 32h224v624z"
                  className=""
                ></path>
                <path
                  d="M736 832h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"
                  className=""
                ></path>
                <path
                  d="M736 704h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"
                  className=""
                ></path>
                <path
                  d="M736 576h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"
                  className=""
                ></path>
              </svg>
              <span className="header-text1 textSM">BLOG</span>
            </div>
          </Link>
        </div>
        <div className="header-bot">
          <svg viewBox="0 0 877.7142857142857 1024" className="header-icon19">
            <path
              d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
              className=""
            ></path>
          </svg>
          <svg viewBox="0 0 1024 1024" className="header-icon21">
            <path
              d="M1024 226.4c-37.6 16.8-78.2 28-120.6 33 43.4-26 76.6-67.2 92.4-116.2-40.6 24-85.6 41.6-133.4 51-38.4-40.8-93-66.2-153.4-66.2-116 0-210 94-210 210 0 16.4 1.8 32.4 5.4 47.8-174.6-8.8-329.4-92.4-433-219.6-18 31-28.4 67.2-28.4 105.6 0 72.8 37 137.2 93.4 174.8-34.4-1-66.8-10.6-95.2-26.2 0 0.8 0 1.8 0 2.6 0 101.8 72.4 186.8 168.6 206-17.6 4.8-36.2 7.4-55.4 7.4-13.6 0-26.6-1.4-39.6-3.8 26.8 83.4 104.4 144.2 196.2 146-72 56.4-162.4 90-261 90-17 0-33.6-1-50.2-3 93.2 59.8 203.6 94.4 322.2 94.4 386.4 0 597.8-320.2 597.8-597.8 0-9.2-0.2-18.2-0.6-27.2 41-29.4 76.6-66.4 104.8-108.6z"
              className=""
            ></path>
          </svg>
          <svg viewBox="0 0 877.7142857142857 1024" className="header-icon23">
            <path
              d="M438.857 73.143c242.286 0 438.857 196.571 438.857 438.857 0 193.714-125.714 358.286-300 416.571-22.286 4-30.286-9.714-30.286-21.143 0-14.286 0.571-61.714 0.571-120.571 0-41.143-13.714-67.429-29.714-81.143 97.714-10.857 200.571-48 200.571-216.571 0-48-17.143-86.857-45.143-117.714 4.571-11.429 19.429-56-4.571-116.571-36.571-11.429-120.571 45.143-120.571 45.143-34.857-9.714-72.571-14.857-109.714-14.857s-74.857 5.143-109.714 14.857c0 0-84-56.571-120.571-45.143-24 60.571-9.143 105.143-4.571 116.571-28 30.857-45.143 69.714-45.143 117.714 0 168 102.286 205.714 200 216.571-12.571 11.429-24 30.857-28 58.857-25.143 11.429-89.143 30.857-127.429-36.571-24-41.714-67.429-45.143-67.429-45.143-42.857-0.571-2.857 26.857-2.857 26.857 28.571 13.143 48.571 64 48.571 64 25.714 78.286 148 52 148 52 0 36.571 0.571 70.857 0.571 81.714 0 11.429-8 25.143-30.286 21.143-174.286-58.286-300-222.857-300-416.571 0-242.286 196.571-438.857 438.857-438.857zM166.286 703.429c1.143-2.286-0.571-5.143-4-6.857-3.429-1.143-6.286-0.571-7.429 1.143-1.143 2.286 0.571 5.143 4 6.857 2.857 1.714 6.286 1.143 7.429-1.143zM184 722.857c2.286-1.714 1.714-5.714-1.143-9.143-2.857-2.857-6.857-4-9.143-1.714-2.286 1.714-1.714 5.714 1.143 9.143 2.857 2.857 6.857 4 9.143 1.714zM201.143 748.571c2.857-2.286 2.857-6.857 0-10.857-2.286-4-6.857-5.714-9.714-3.429-2.857 1.714-2.857 6.286 0 10.286s7.429 5.714 9.714 4zM225.143 772.571c2.286-2.286 1.143-7.429-2.286-10.857-4-4-9.143-4.571-11.429-1.714-2.857 2.286-1.714 7.429 2.286 10.857 4 4 9.143 4.571 11.429 1.714zM257.714 786.857c1.143-3.429-2.286-7.429-7.429-9.143-4.571-1.143-9.714 0.571-10.857 4s2.286 7.429 7.429 8.571c4.571 1.714 9.714 0 10.857-3.429zM293.714 789.714c0-4-4.571-6.857-9.714-6.286-5.143 0-9.143 2.857-9.143 6.286 0 4 4 6.857 9.714 6.286 5.143 0 9.143-2.857 9.143-6.286zM326.857 784c-0.571-3.429-5.143-5.714-10.286-5.143-5.143 1.143-8.571 4.571-8 8.571 0.571 3.429 5.143 5.714 10.286 4.571s8.571-4.571 8-8z"
              className=""
            ></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

Header.defaultProps = {
  rootClassName: '',
  heading11: 'Home',
  heading1: 'Home',
  heading2: 'FAQs',
  heading3: 'Gallery',
}

Header.propTypes = {
  rootClassName: PropTypes.string,
  heading11: PropTypes.string,
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  heading3: PropTypes.string,
}

export default Header
