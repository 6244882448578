import { React, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Label from '../components/label'
import Review from '../components/reviews/reviews'
import MemberDetails from '../components/member-details'
import ServiceCard from '../components/service-card'
import SecondaryButton from '../components/secondary-button'
import Footer from '../components/footer'
import './home.css'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Home = (props) => {
  useEffect(() => {
    const ifVH100 = () => {
    if (window.scrollY > (getWindowDimensions().height * 1.5)) {
      document.querySelector(".home-hero").style.opacity = 0
    } else {
      document.querySelector(".home-hero").style.opacity = 1
    }
  }
  window.addEventListener("scroll", ifVH100);
  ifVH100();
  return () => window.removeEventListener("scroll", ifVH100);
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>TRIPLE C</title>
        <meta property="og:title" content="Triple C" />
      </Helmet>
      <div className="home-hero">
        <div className="home-bg">
        </div>
        <div className="home-overlay">
        </div>
        <div className="home-container01">
          <div className="home-container02">
            <h1 className="home-text">TRIPLE C</h1>
            <span className="home-text01">
              Clinical<span className='home-text-spacing'> </span>|<span className='home-text-spacing'> </span>Caring<span className='home-text-spacing'> </span>|<span className='home-text-spacing'> </span>Cupping
            </span>
          </div>
        </div>
      </div>
      <div className="home-section1">
        <div className="home-container03">
          <div className="home-container04">
            <h2 className='home-text011'>﷽</h2>
            <h3 className="home-text02 healine">Welcome to TRIPLE C</h3>
            <span className="home-text03 textXL">
              Established in 2020, TRIPLE C Therapy strives to master the basics, providing much needed assistance to the local and wider community.
              <br></br>
              <br></br>
              By reviving sunnah remedies to treat a variety of spiritual and physical ailments, our reviews are testament to the impact we have had on our diverse portfolio of clients.
            </span>
          </div>
          <div className="home-cards-container">
            <div className="home-card1">
              <div className="home-container05">
                <svg viewBox="0 50 1024 1024" className="home-icon">
                <path d="M896 256h-192v-128c0-35.2-28.8-64-64-64h-256c-35.2 0-64 28.8-64 64v128h-192c-70.4 0-128 57.6-128 128v512c0 70.4 57.6 128 128 128h768c70.4 0 128-57.6 128-128v-512c0-70.4-57.6-128-128-128zM384 128h256v128h-256v-128zM768 704h-192v192h-128v-192h-192v-128h192v-192h128v192h192v128z"></path>
                </svg>
              </div>
              <h6 className="home-text04 textXL">Clinical</h6>
              <span className="home-text05">
              Mastering the fundamentals, all of our therapists are accredited by the IPHM and CMA to ensure the correct measures are implemented.
              </span>
            </div>
            <div className="home-card2">
              <div className="home-container06">
                <svg
                  viewBox="0 -40 1024 1024" 
                  className="home-icon02"
                >
                  <path d="M512 950.857c-9.143 0-18.286-3.429-25.143-10.286l-356.571-344c-4.571-4-130.286-118.857-130.286-256 0-167.429 102.286-267.429 273.143-267.429 100 0 193.714 78.857 238.857 123.429 45.143-44.571 138.857-123.429 238.857-123.429 170.857 0 273.143 100 273.143 267.429 0 137.143-125.714 252-130.857 257.143l-356 342.857c-6.857 6.857-16 10.286-25.143 10.286z"></path>
                </svg>
              </div>
              <h6 className="home-text06 textXL">Caring</h6>
              <span className="home-text07">
              Whether you'd like peace and quiet during your session, or a friendly face to converse with, we've got your back (quite literally).
              That is why none of our packages have any time limits. 
              </span>
            </div>
            <div className="home-card3">
              <div className="home-container07">
              <svg
	 viewBox="0 0 1024 1024" 
   className="home-icon02">
	<g>
		<path d="M911.2,893.8c7.9,9.4,9.1,13.3,8.9,28.9c0,13.1-0.4,15.7-4,23c-5.3,10.9-14.3,19-26.8,24.3c-10.1,4.2-10.1,4.2-374.8,4.5
			c-278,0.1-366.6-0.1-372.7-1.4c-25.6-5.4-42.6-28.1-40.6-53.9c1-13.3,5.6-23,14.3-31c0.9-0.7,2.9-2.8,4.8-4.5
			c4.1-4,24.6-13.1,29.1-13.1c3.2,0,3.7-0.7,4.3-7.9c0.9-9.3,1.3-81.2,1.2-192c0-91.1,1.3-117.2,7.9-147.4
			c26.7-118.2,105.6-208.5,222.8-253c21.1-8.5,22-8.9,23-13.4c1.3-5.6,8.9-42.2,12.2-58.1c4.2-21.4,13.3-36.8,27.5-47.4
			c4.2-3.1,8.6-6.5,9.8-7.5c1.9-1.6,2.3-6.4,2.6-25.1c0.1-12.8,0.7-26.1,1.3-29.6c1.9-14.1,13.9-29.6,28.1-36.3
			c7-3.4,10-3.9,20.6-3.9v-0.7c10.5,0,13.6,0.6,20.5,3.8c10.3,4.9,16,10.2,22.8,21.2c5.6,8.7,5.6,8.7,6.7,37.1
			c0.7,15.5,1.6,29.5,1.9,31c0.4,1.6,3.4,4.4,6.7,6.3c11.6,6.8,23.1,22,28.4,37.4c3.5,11.7,9.9,47.7,13,61.1
			c3.1,14.7,3.1,14.7,9.7,17.2c52.3,21,61.8,25.5,86.4,41.3c38.3,24.7,71.6,55.8,100.3,97.5c32.5,46.7,52.9,103.5,57.8,162.4
			c0.7,9.3,1.6,81.8,1.8,161.8c0.4,144.3,0.4,144.3,4.3,144.3c6.2,0,21.7,6.6,28.1,12C907.6,889.2,907.3,888.9,911.2,893.8z
			 M885,939.7c6.9-7.3,9.4-15.4,6.7-22.3c-2.6-7.5-6.7-12.4-12-15.1c-4.3-2-40-2.1-368.9-2.3c-363.9,0-364.1,0-369.2,3
			c-7.2,4.1-12.2,14.5-11,23.3c1.3,8.6,2.6,10.7,10,15.7c6.2,4.1,6.1,4.1,130.8,4.8c154.1,0.8,590.5,0.3,600.9-0.8
			C878.7,945.3,880.6,944.3,885,939.7z M836.4,869.2V717.3c0-162.3,0-162.8-7.3-194.4c-23.6-109.8-106.9-196.5-215.8-232.7
			c-1.6-0.7-4.2-1.6-5.8-1.8c-32.4-9.6-70.5-14.8-107.3-13.4v-0.7C371,276.6,253,358,206.9,471.8c-10.1,24.8-16.8,50.8-21.8,83.6
			c-1.2,8.5-1.8,108.6-1.5,301.4v12.3C346.3,869.2,673.7,869.2,836.4,869.2z M578.1,253.8c3.2,0.8,3.4,0.6,2.5-4
			c-0.6-2.7-1.6-8.6-2.3-13.2c-4.4-27.8-8.1-43.3-12.2-51.2c-3.4-6.6-13.6-15.8-17.6-15.8c-1.3,0-2.8-0.6-3.2-1.4
			c-0.6-0.8-13.6-1.4-34.3-1.4V166c-37.9,0-40.6,0.6-51,10.9c-12.4,10.3-14.1,34.6-18.2,51.5c-0.7,3.2-1.3,8.2-1.3,11
			s-0.7,6.3-1.6,7.9c-0.9,1.7-1.2,3.8-0.7,4.9c0.6,1.3,1.9,1.8,3.4,1.4C463.9,247.7,547,246.9,578.1,253.8z M530.6,137.7
			c2-0.7,2.3-4.2,2.3-24c0-19.7-0.4-23.7-2.6-27.2c-4.7-7.5-10.2-10.6-18.4-10.6c-9.7,0-15.5,3.1-19.6,10.4
			c-2.9,5.4-3.2,7.8-3.2,28.2c0,20.2,0.3,22.4,2.6,23C495.5,138.5,528,138.7,530.6,137.7z"/>
		<path d="M891.8,917.4c2.6,6.9,0.1,14.9-6.7,22.3c-4.4,4.7-6.3,5.6-12.7,6.2c-10.4,1.1-446.8,1.7-600.9,0.8
			c-124.6-0.7-124.6-0.7-130.8-4.8c-7.3-4.9-8.6-7.1-10-15.7c-1.2-8.8,3.8-19.2,11-23.3c5.1-3,5.3-3,369.2-3
			c328.9,0.1,364.6,0.3,368.9,2.3C885,905,889.1,909.9,891.8,917.4z"/>
		<path d="M836.4,717.3v151.9c-162.7,0-490.1,0-652.7,0v-12.3c-0.3-192.8,0.3-293,1.5-301.4c5-32.9,11.7-58.8,21.8-83.6
			C253,358,371,276.6,500.1,274.2v0.7c36.8-1.4,74.9,3.8,107.3,13.4c1.6,0.3,4.2,1.1,5.8,1.8C722.2,326.4,805.5,413.1,829,522.9
			C836.4,554.5,836.4,554.9,836.4,717.3z"/>
		<path d="M580.6,249.8c0.9,4.5,0.7,4.8-2.5,4c-31-6.9-114.1-6.1-136.5-0.1c-1.5,0.4-2.8-0.1-3.4-1.4c-0.4-1.1-0.1-3.2,0.7-4.9
			c0.9-1.6,1.6-5.1,1.6-7.9s0.6-7.8,1.3-11c4.1-16.9,5.8-41.2,18.2-51.5c10.4-10.3,13-10.9,51-10.9v0.7c20.6,0,33.7,0.6,34.3,1.4
			c0.4,0.8,1.9,1.4,3.2,1.4c3.9,0,14.2,9.2,17.6,15.8c4.1,7.9,7.8,23.4,12.2,51.2C578.9,241.2,580,247.1,580.6,249.8z"/>
		<path d="M533,113.7c0,19.8-0.3,23.3-2.3,24c-2.6,1-35.1,0.8-39-0.1c-2.3-0.6-2.6-2.8-2.6-23c0-20.5,0.3-22.9,3.2-28.2
			c4.1-7.3,10-10.4,19.6-10.4c8.2,0,13.8,3.1,18.4,10.6C532.5,90,533,93.9,533,113.7z"/>
	</g>
</svg>
            </div>
              <h6 className="home-text08 textXL">Cupping</h6>
              <span className="home-text09">
              Superficial incisions with pinpoint precision, and unlimited cups across all sessions.<br></br> Yes! You read that right!
              </span>
            </div>
          </div>
          <div className="home-work-with-us">
            <div className="home-container08">
              <div className="home-container09">
                <svg viewBox="0 0 1024 1024" className="home-icon06">
                <path d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"></path>
                </svg>
              </div>
              <h3 className="home-text10 healine">Outcalls and incalls available</h3>
              <span className="home-text11">
                <span className="home-text12">
                We are proud to be able to offer incall treatments in Hounslow, South Harrow and surrounding areas in West London.
                <br></br>
                Alternatively, if you'd like an outcall session our services are also mobile, bringing TRIPLEC to your door for a small outcall fee in surrounding areas.
                </span>
                <br></br>
              </span>
              <span className="home-text14">Get in contact with us today!</span>
            </div>
            {/* <div className="home-container10">
              <div className="home-container11">
              </div>
                <div className="home-container12">
                <h4 className="home-text15 healine">
                  <span>Qualified incall centres</span>
                  <br></br>
                </h4>
                <span className="home-text18">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </span>
                  <br></br>
                </span>
              </div> 
            </div> */}
          </div>
        </div>
      </div>
      <div className="home-section2">
      <div className="home-container22">
          <h6 className="home-text30">THE TEAM</h6>
          {/* <h3 className="home-text31">Who we are</h3> */}
          <span className="home-text32">
          We all have our own reasons for wanting to better our health and wellbeing. I was in the same position upon discovering cupping therapy at 19. A bigger picture.
          Being intrigued by holistic remedies and wanting to find a natural answer to illnesses without the side effects was mine. A gargantuan amount of cups and a few years later, here we are.
          <br></br>
          Being socially approachable is integral to forming bonds with our clients, which is why you will always be met with a smile at the door.
          <br></br>
          <br></br>
          Therapy is present in our lives in various ways.
          <br></br>
          With TRIPLE C Therapy, if you enter stressed, you shall leave revitalised. 
          <br></br>
          </span>
          <span className='home-text32-subtext textSM'>Abu Idrees</span>
        </div>
        <div className="home-team">
          <div className="home-container23">
              <div className="home-container24">
                <MemberDetails 
                heading1="Abu Idrees"
                heading11={"MALE CUPPING EXPERT\nFOUNDER OF TRIPLE C THERAPY"}
                image_src="/assets/faces/male-1.svg"></MemberDetails>
              </div>
              {/* <div className="home-container29">
                <MemberDetails
                  image_src="/playground_assets/team2-200h.jpg"
                ></MemberDetails>
              </div> */}
          </div>
        </div>
        <div className="home-container13">
          <h2 className="home-text21 text2XL">
            <span className="home-text22">
              Reviews
            </span>
            <br></br>
          </h2>
          <span className="home-text24 textXL">
            Want to know more? Have a read of a variety of reviews from our esteemed clients:
          </span>
        </div>
        <div className="home-growing-company-section">
          <div className="home-container14">            
          <Review></Review>
          </div>
        </div>
      </div>
      <div className="home-section3">
        <div className="home-container33">
        <h2 className="home-text40 text2XL">
              Price Packages
            <br></br>
          </h2>
          <div className="home-services">
            <ServiceCard
              text={"Percussion massage\nOlive oil massage\nLower body hijama\nUnlimited cups!"}
              heading="Lower body relief"
              price={"£30"}
            ></ServiceCard>
            <ServiceCard
              text={"Percussion massage\nOlive oil massage\nBack and head hijama\nUnlimited cups!"}
              heading="Back and head detox"
              rootClassName="service-card-root-class-name1"
              price={"£40"}
            ></ServiceCard>
            <ServiceCard
              text={"Extensive percussion massage\nOlive oil massage\nFire cupping\nFull body hijama\nBlack seed oil massage\nUnlimited cups!"}
              heading="Full body platinum"
              rootClassName="service-card-root-class-name"
              price={"£90"}
            ></ServiceCard>
          </div>
          <div className="home-contact">
            <Label text="MESSAGE US" colorStyle="label-color-style2"></Label>
            <h2 className="home-text33">Get in contact with us</h2>
            <span className="home-text34 textXL">
            For all pricing enquiries and custom packages please contact us directly using the form below.
            </span>
            <div className="home-form">
              <h1 className="home-text35">Have any questions?</h1>
              <span className="home-text36 textXL">
                Complete this form and we will get back to you in 24 hours.
              </span>
              <span className="home-text37 textXS">FULL NAME</span>
              <input
                type="text"
                placeholder="Full Name"
                className="home-textinput textSM input"
              />
              <span className="home-text38 textXS">EMAIL</span>
              <input
                type="text"
                placeholder="Email"
                className="home-textinput1 textSM input"
              />
              <span className="home-text39 textXS">MESSAGE</span>
              <textarea
                cols="80"
                rows="4"
                placeholder="Type a message"
                className="home-textarea textSM textarea"
              ></textarea>
              <div className="home-container34">
                <SecondaryButton
                  button="Send message"
                  rootClassName="secondary-button-root-class-name"
                ></SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
