import React, { useState } from 'react'

import { Helmet } from 'react-helmet'


import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Photos from "../components/photos";

import Footer from '../components/footer'
import './gallery.css'

const Gallery = (props) => {
  const [index, setIndex] = useState(-1);

  return (
    <div className="profile-container">
      <Helmet>
        <title>Profile - Notus Pro</title>
        <meta property="og:title" content="Profile - Notus Pro" />
      </Helmet>
      <div className="profile-image">
        <div className="profile-bg"></div>
      </div>

    <div className="gallery">
      <RowsPhotoAlbum photos={Photos} targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

      <Lightbox
        slides={Photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Thumbnails]}
      />
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Gallery
